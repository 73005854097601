import React, {useState, useEffect} from 'react';

import gregoryCsh from "../../images/gregory-cs.png";
import kathCsh from "../../images/katherine-cs.png";
import marthaCsh from "../../images/martha-cs.png"
import peterCsh from "../../images/peter-cs.png";

import plus from "../../images/plus.svg";
import minus from "../../images/minus.svg";
import activeMag from "../../images/activemag.png";

import CsGreg from "./cs-greg";
import CsKath from "./cs-kath";
import CsMartha from "./cs-martha";
import CsPeter from "./cs-peter";

const CasestudyModule =()=>{
  const [currentActive, setCurrentActive] = useState('');

  const toggleCurrentActive =(e)=>{
    let tc = e.currentTarget.dataset.cs;
    if(tc.length){
      setCurrentActive(tc);
    }else{
      setCurrentActive('');
    }
    e.preventDefault();
  }

  const checkActiveName =(name)=>{
    let cn = name;
    if(cn === currentActive){
      return true;
    }else{
      return false;
    }
  }

  const checkActiveNameImgClass =(name)=>{
    let cn = name;
    if(cn === currentActive){
      return '';
    }else{
      return 'isMuted';
    }
  }

  const StoryWrapper =({children})=>{
    return(
      <div className="columns small-12 text-left lg_mt-2 mt-2 lg_mb-2 mb-2">
        {children}
      </div>
    )
  }

  useEffect(()=>{
    if(currentActive.length){
      let elements = document.getElementsByClassName(currentActive);
      let ot = ''
      let iw = typeof window !== "undefined" ? window.innerWidth : '';
      if(parseInt(elements[0].offsetTop) === 0){
        //console.log(`waz 0 ${elements[1].offsetTop}`);
        //desktop ct
        window.scrollTo({
          top: elements[1].offsetTop + 280,
          behavior: 'smooth'
        });
      }else{
        //console.log(`waz 1 ${elements[0].offsetTop}`);
        //mobile ct
        window.scrollTo({
          top: elements[0].offsetTop + 150,
          behavior: 'smooth'
        });
      }
    }
  },[currentActive]);

  return(
    <>
      <div className="row cs-module">
        {/*clicker*/}
        <div className="columns small-12 medium-6 text-center">
          <div className="case-study__clicker">
            <div className={currentActive.length ? `case-study__img-target ${checkActiveNameImgClass('greg-cs')}` : `case-study__img-target` }>
              <img alt="Patient Gregory headshot" src={gregoryCsh} width="305" height="auto"/>
              <div className={checkActiveName('greg-cs') ? `case-study__img-active isActive` : `case-study__img-active` } style={{"--active-mag-img": `url(${activeMag})`}}></div>
              {!currentActive.length &&
                <>
                <a className="case-study__click-mask" href="#case-greg" data-cs="greg-cs" onClick={(e)=>toggleCurrentActive(e)}></a>
                <button data-cs="greg-cs" onClick={(e)=>toggleCurrentActive(e)}><span className="visually-hidden">Expand this case study</span> <img src={plus}/></button>
                </>
              }
              {currentActive.length > 0 &&
                <>
                { checkActiveName('greg-cs') &&
                  <>
                  <a className="case-study__click-mask" href="#" data-cs="" onClick={(e)=>toggleCurrentActive(e)}></a>
                  <button data-cs="" onClick={(e)=>toggleCurrentActive(e)}><span className="visually-hidden">Close this case study</span> <img src={minus}/></button>
                  </>
                }
                { !checkActiveName('greg-cs') &&
                  <>
                  <a className="case-study__click-mask" href="#case-greg" data-cs="greg-cs" onClick={(e)=>toggleCurrentActive(e)}></a>
                  <button data-cs="greg-cs" onClick={(e)=>toggleCurrentActive(e)}><span className="visually-hidden">Expand this case study</span> <img src={plus}/></button>
                  </>
                }
                </>
              }
            </div>
            <div className="lg_mt-1">
              <p className="h4 mb-0"><span className="color--powder h2 text--watch-quinn">Gregory, </span> <span className="uppercase text--regular color--purple">Age 67</span></p>
              <p className="color--purple strong mb-0">Presenting to his hematologist/oncologist</p>
              <p className="color--purple text--regular" style={{marginTop: `2px`}}>Not an actual patient.</p>
            </div>
          </div>
        </div>
        {/*clicker*/}
        <div className="hide-for-medium">
        {/*story*/}
          {checkActiveName('greg-cs') &&
            <StoryWrapper>
              <CsGreg/>
            </StoryWrapper>
          }
        {/*story*/}
        </div>
        {/*clicker*/}
        <div className="columns small-12 medium-6 mt-1 text-center">
          <div className="case-study__clicker">
            <div className={currentActive.length ? `case-study__img-target ${checkActiveNameImgClass('kath-cs')}` : `case-study__img-target` }>
              <img alt="Patient Katherine headshot" src={kathCsh} width="305" height="auto"/>
              <div className={checkActiveName('kath-cs') ? `case-study__img-active isActive` : `case-study__img-active` } style={{"--active-mag-img": `url(${activeMag})`}}></div>
              {!currentActive.length &&
                <>
                <a className="case-study__click-mask" href="#case-kath" data-cs="kath-cs" onClick={(e)=>toggleCurrentActive(e)}></a>
                <button data-cs="kath-cs" onClick={(e)=>toggleCurrentActive(e)}><span className="visually-hidden">Expand this case study</span> <img src={plus}/></button>
                </>
              }
              {currentActive.length > 0 &&
                <>
                { checkActiveName('kath-cs') &&
                  <>
                  <a className="case-study__click-mask" href="#" data-cs="" onClick={(e)=>toggleCurrentActive(e)}></a>
                  <button data-cs="" onClick={(e)=>toggleCurrentActive(e)}><span className="visually-hidden">Close this case study</span> <img src={minus}/></button>
                  </>
                }
                { !checkActiveName('kath-cs') &&
                  <>
                  <a className="case-study__click-mask" href="#case-kath" data-cs="kath-cs" onClick={(e)=>toggleCurrentActive(e)}></a>
                  <button data-cs="kath-cs" onClick={(e)=>toggleCurrentActive(e)}><span className="visually-hidden">Expand this case study</span> <img src={plus}/></button>
                  </>
                }
                </>
              }
            </div>
            <div className="lg_mt-1">
              <p className="h4 mb-0"><span className="color--powder h2 text--watch-quinn">Katherine, </span> <span className="uppercase text--regular color--purple">Age 42</span></p>
              <p className="color--purple strong mb-0">Presenting to her dermatologist</p>
              <p className="color--purple text--regular" style={{marginTop: `2px`}}>Not an actual patient.</p>
            </div>
          </div>
        </div>
        {/*clicker*/}
        <div className="hide-for-medium">
        {/*story*/}
          {checkActiveName('kath-cs') &&
            <StoryWrapper>
              <CsKath/>
            </StoryWrapper>
          }
        {/*story*/}
        </div>
        <div className="show-for-medium">
          {/*story*/}
            {checkActiveName('greg-cs') &&
              <StoryWrapper>
                <CsGreg/>
              </StoryWrapper>
            }
          {/*story*/}
          {/*story*/}
            {checkActiveName('kath-cs') &&
              <StoryWrapper>
                <CsKath/>
              </StoryWrapper>
            }
          {/*story*/}
        </div>
        {/*clicker*/}
        <div className="columns lg_mt-4 mt-1 small-12 medium-6 text-center">
          <div className="case-study__clicker">
            <div className={currentActive.length ? `case-study__img-target ${checkActiveNameImgClass('martha-cs')}` : `case-study__img-target` }>
              <img alt="Patient Martha headshot" src={marthaCsh} width="305" height="auto"/>
              <div className={checkActiveName('martha-cs') ? `case-study__img-active isActive` : `case-study__img-active` } style={{"--active-mag-img": `url(${activeMag})`}}></div>
              {!currentActive.length &&
                <>
                <a className="case-study__click-mask" href="#case-martha" data-cs="martha-cs" onClick={(e)=>toggleCurrentActive(e)}></a>
                <button data-cs="martha-cs" onClick={(e)=>toggleCurrentActive(e)}><span className="visually-hidden">Expand this case study</span> <img src={plus}/></button>
                </>
              }
              {currentActive.length > 0 &&
                <>
                { checkActiveName('martha-cs') &&
                  <>
                  <a className="case-study__click-mask" href="#" data-cs="" onClick={(e)=>toggleCurrentActive(e)}></a>
                  <button data-cs="" onClick={(e)=>toggleCurrentActive(e)}><span className="visually-hidden">Close this case study</span> <img src={minus}/></button>
                  </>
                }
                { !checkActiveName('martha-cs') &&
                  <>
                  <a className="case-study__click-mask" href="#case-martha" data-cs="martha-cs" onClick={(e)=>toggleCurrentActive(e)}></a>
                  <button data-cs="martha-cs" onClick={(e)=>toggleCurrentActive(e)}><span className="visually-hidden">Expand this case study</span> <img src={plus}/></button>
                  </>
                }
                </>
              }
            </div>
            <div className="lg_mt-1">
              <p className="h4 mb-0"><span className="color--powder h2 text--watch-quinn">Martha, </span> <span className="uppercase text--regular color--purple">Age 53</span></p>
              <p className="color--purple strong mb-0">Presenting to ER</p>
              <p className="color--purple text--regular" style={{marginTop: `2px`}}>Not an actual patient.</p>
            </div>
          </div>
        </div>
        {/*clicker*/}
        <div className="hide-for-medium">
          {/*story*/}
            {checkActiveName('martha-cs') &&
              <StoryWrapper>
                <CsMartha/>
              </StoryWrapper>
            }
          {/*story*/}
        </div>
        {/*clicker*/}
        <div className="columns lg_mt-4 mt-1 small-12 medium-6 text-center">
          <div className="case-study__clicker">
            <div className={currentActive.length ? `case-study__img-target ${checkActiveNameImgClass('peter-cs')}` : `case-study__img-target` }>
              <img alt="Patient Peter headshot" src={peterCsh} width="305" height="auto"/>
              <div className={checkActiveName('peter-cs') ? `case-study__img-active isActive` : `case-study__img-active` } style={{"--active-mag-img": `url(${activeMag})`}}></div>
              {!currentActive.length &&
                <>
                <a className="case-study__click-mask" href="#case-peter" data-cs="peter-cs" onClick={(e)=>toggleCurrentActive(e)}></a>
                <button data-cs="peter-cs" onClick={(e)=>toggleCurrentActive(e)}><span className="visually-hidden">Expand this case study</span> <img src={plus}/></button>
                </>
              }
              {currentActive.length > 0 &&
                <>
                { checkActiveName('peter-cs') &&
                  <>
                  <a className="case-study__click-mask" href="#" data-cs="" onClick={(e)=>toggleCurrentActive(e)}></a>
                  <button data-cs="" onClick={(e)=>toggleCurrentActive(e)}><span className="visually-hidden">Close this case study</span> <img src={minus}/></button>
                  </>
                }
                { !checkActiveName('peter-cs') &&
                  <>
                  <a className="case-study__click-mask" href="#case-peter" data-cs="peter-cs" onClick={(e)=>toggleCurrentActive(e)}></a>
                  <button data-cs="peter-cs" onClick={(e)=>toggleCurrentActive(e)}><span className="visually-hidden">Expand this case study</span> <img src={plus}/></button>
                  </>
                }
                </>
              }
            </div>
            <div className="lg_mt-1">
              <p className="h4 mb-0"><span className="color--powder h2 text--watch-quinn">Peter, </span> <span className="uppercase text--regular color--purple">Age 55</span></p>
              <p className="color--purple strong mb-0">Presenting to his gastroenterologist</p>
              <p className="color--purple text--regular" style={{marginTop: `2px`}}>Not an actual patient.</p>
            </div>
          </div>
        </div>
        {/*clicker*/}
        <div className="hide-for-medium">
          {/*story*/}
            {checkActiveName('peter-cs') &&
              <StoryWrapper>
                <CsPeter/>
              </StoryWrapper>
            }
          {/*story*/}
        </div>
        <div className="show-for-medium">
          {/*story*/}
            {checkActiveName('martha-cs') &&
              <StoryWrapper>
                <CsMartha/>
              </StoryWrapper>
            }
          {/*story*/}
          {/*story*/}
            {checkActiveName('peter-cs') &&
              <StoryWrapper>
                <CsPeter/>
              </StoryWrapper>
            }
          {/*story*/}
        </div>
      </div>
    </>
  )
}

export default CasestudyModule;
