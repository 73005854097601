import * as React from "react";

const CsGreg =()=>{
  return(
    <>
      <div className="row align-center greg-cs">

        <div className="columns small-12 large-12 lg_mb-2 mb-2 small-collapse medium-uncollapse">
          <div className="hr"></div>
        </div>

        <div className="columns small-11 large-11">
          <p className="strong color--powder h3">Gregory:</p>
          <p className="strong color--purple h4">SM-AHN confirmed on second evaluation</p>
          <div className="lg_mt-2 mt-2">
            <p className="strong h5 color--purple">Patient history and presentation:</p>
            <ul className="ul-standard color--purple text--regular">
              <li>Initial signs and symptoms and pathology workup confirmed Gregory’s diagnosis of chronic myelomonocytic leukemia (CMML). At the time of CMML diagnosis, next-generation sequencing (NGS) on bone marrow detected TET2, SRSF2, RUNX1, and KIT D816V mutations</li>
              <li>After initiating first-line therapy with a hypomethylating agent, patient returned for follow-up due to:
                <ul>
                  <li>Unresolved splenomegaly</li>
                  <li>Recent onset of bone pain</li>
                  <li>Worsening fatigue</li>
                  <li>Unexplained weight loss</li>
                  <li>Increased episodes of diarrhea</li>
                </ul>
              </li>
            </ul>
          </div>

          <div className="lg_mt-2 mt-2">
            <p className="strong h5 color--purple">Full lab results:</p>
            <ul className="ul-standard color--purple text--regular">
              <li>Complete blood count and chemistry profile normal</li>
              <li>Serum tryptase: persistently elevated, 110 ng/mL</li>
              <li>KIT D816V in bone marrow with high-sensitivity assay: reconfirmed positive mutation status</li>
              <li>IHC staining for CD25 and CD117 on bone marrow biopsy revealed CD25 spindle-shaped mast cells of &gt;15 cells per cluster, &gt;35% infiltration of cellularity by mast cells</li>
            </ul>
          </div>
        </div>

        <div className="columns small-12 large-11 lg_mt-3 mt-3">
          <div className="yellow-card yellow-card--even yellow-card--right text-left">
            <p className="h3 mb-0">Due to the bone marrow mast cell aggregates and a confirmed KIT D816V mutation status, <strong>Gregory's diagnosis was revised to SM with an associated hematological neoplasm (SM-AHN), where the -AHN component was CMML.</strong></p>
          </div>
        </div>

      </div>
    </>
  );
}

export default CsGreg;
