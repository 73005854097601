import * as React from "react";

const CsMartha =()=>{
  return(
    <>
      <div className="row align-center martha-cs">

      <div className="columns small-12 large-12 lg_mb-2 mb-2 small-collapse medium-uncollapse">
        <div className="hr"></div>
      </div>

        <div className="columns small-11 large-11">
          <p className="strong color--powder h3">Martha:</p>
          <p className="strong color--purple h4">Indolent systemic mastocytosis presenting with recurrent anaphylactic episodes</p>
          <div className="lg_mt-2 mt-2">
            <p className="strong h5 color--purple">Patient history and presentation:</p>
            <ul className="ul-standard color--purple text--regular">
              <li>Martha presented to ER after going into anaphylactic shock with hypotension from a bee sting</li>
              <li>Past medical history of chronic urticaria, managed with high-dose antihistamines</li>
              <li>Reported increased frequency of unexplained anaphylaxis (3 episodes in previous month)</li>
              <li>Recent onset of diarrhea of daily occurrence</li>
              <li>Blood work revealed heightened risk for future anaphylactic episodes with elevated IgE and baseline serum tryptase (106 ng/mL)</li>
              <li>Referred to allergist on suspicion of underlying mast cell involvement</li>
            </ul>
          </div>

          <div className="lg_mt-2 mt-2">
            <p className="strong h5 color--purple">Full lab results:</p>
            <ul className="ul-standard color--purple text--regular">
              <li className="ul-standard">
                From allergist:
                <ul>
                  <li>Complete blood count and chemistry profile normal</li>
                  <li>Serum tryptase: persistently elevated, 110 ng/mL</li>
                  <li>Received MCAS diagnosis and referred to hematologist</li>
                </ul>
              </li>
              <li>From hematologist:
                <ul>
                  <li>IHC staining for CD25 and CD117 on bone marrow biopsy revealed CD25 spindle-shaped mast cells of &gt;15 cells per cluster, &gt;35% inﬁltration of cellularity by mast cells</li>
                  <li>KIT D816V in peripheral blood with high-sensitivity assay: positive mutation status</li>
                </ul>
              </li>
            </ul>
          </div>
        </div>

        <div className="columns small-12 large-11 lg_mt-3 mt-3">
          <div className="yellow-card yellow-card--even yellow-card--right text-left">
            <p className="h3 mb-0">With a positive KIT D816V mutation status, &gt;35% infiltration of cellularity by mast cells, aberrant expression of CD25, and irregular, spindle-shaped mast cells, <strong>the hematologist diagnosed Martha with indolent systemic mastocytosis</strong>.</p>
          </div>
        </div>

      </div>
    </>
  );
}

export default CsMartha;
