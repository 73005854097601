import * as React from "react";

const CsKath =()=>{
  return(
    <>
      <div className="row align-center kath-cs">

        <div className="columns small-12 large-12 lg_mb-2 mb-2 small-collapse medium-uncollapse">
          <div className="hr"></div>
        </div>

        <div className="columns small-11 large-11">
          <p className="strong color--powder h3">Katherine:</p>
          <p className="strong color--purple h4">Indolent systemic mastocytosis presenting with skin lesions</p>
          <div className="lg_mt-2 mt-2">
            <p className="strong h5 color--purple">Patient history and presentation:</p>
            <ul className="ul-standard color--purple text--regular">
              <li>After presenting to her local dermatologist with bothersome maculopapular lesions on her torso and upper thigh, Katherine was diagnosed with cutaneous mastocytosis</li>
              <li>She was prescribed topical steroids for a year with limited relief</li>
              <li>Referred to allergist for second opinion after reporting recent onset of headaches, increasing fatigue, and diarrhea</li>
              <li>Had constant trouble focusing; expressed inability to work due to her symptoms. Reported increased frequency of “flare-ups” and inadequacy of over-the-counter medications, such as H1, H2 blockers, in symptom management</li>
              <li>Lab results came back showing elevated serum tryptase at &gt;100 ng/mL</li>
            </ul>
          </div>

          <div className="lg_mt-2 mt-2">
            <p className="strong h5 color--purple">Full lab results:</p>
            <ul className="ul-standard color--purple text--regular">
              <li>Complete blood count, chemistry profile, and liver function tests normal: overall stable organ function</li>
              <li>Follow-up serum tryptase: persistently elevated, 130 ng/mL</li>
              <li>KIT D816V in peripheral blood with high-sensitivity assay: positive mutation status</li>
              <li>Referred to hematologist/oncologist to conduct bone marrow biopsy. Pathology report revealed ~35% spindle-shaped bone marrow mast cells</li>
            </ul>
          </div>
        </div>

        <div className="columns small-12 large-11 lg_mt-3 mt-3">
          <div className="yellow-card yellow-card--even yellow-card--right text-left">
            <p className="h3 mb-0">With persistently elevated serum tryptase, &gt;30% mast cell infiltration of bone marrow, and a positive KIT D816V mutation status, <strong>Katherine’s diagnosis was revised to indolent systemic mastocytosis</strong>.</p>
          </div>
        </div>

      </div>
    </>
  );
}

export default CsKath;
