import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

import ReferenceBlock from "../components/template-partials/reference-block";
import FooterCrown from "../components/template-partials/footer-crown";

import RoundChart from "../components/template-partials/round-chart";
import CasestudyModule from "../components/template-partials/case-study-module";

import { AnchorLink } from "gatsby-plugin-anchor-links";



import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";

import assays from "../images/dyk-ls-assays.png";
import assaysMobile from "../images/dyk-ls-assays-mbl.png";
import assaysBg from "../images/dyk-assays-bg.png";
import pdfDownload from "../images/pdfdownload.svg";

const SuspectSMPage = () => (
  <Layout route="suspect-sm">

    <Seo page="suspect-sm" title="Is it SM?" description="Recognize these hallmark symptoms in your patients? Review common misdiagnoses, patient profiles, and steps to confirm an SM diagnosis." />

    <div className="row lg_mt-3 mt-1 align-center medium-align-left">
      <div className="columns small-11 large-10">
        <h1 className="color--purple text--watch-quinn">Recognizing hallmark symptoms may help with earlier identification of SM<sup>1</sup></h1>
      </div>
    </div>

    <div id="screen-for-sm" className="row lg_mt-5 mt-3 align-center medium-align-left">
      <div className="columns small-11 medium-expand">
        <p className="h3 strong color--purple">Skin lesions, anaphylaxis, diarrhea? <br className="show-for-medium"/>Suspect systemic mastocytosis (SM)</p>
      </div>
    </div>

    <div className="row lg_mt-5 mt-3 align-center medium-align-left">
      <div className="columns text-center small-11 medum-expand">
        <p className="h3 strong color--purple">Symptoms of SM can mimic other disorders</p>
        <p className="h4 color--purple text--regular lg_mt-1 mt-1">Select each circle to see how these disorders are different than SM</p>
      </div>
    </div>

    <div className="row expanded lg_mt-4 mt-4" style={{maxWidth: `1300px`}}>
      <div className="columns small-12 large-3 text-center">
        <RoundChart>
          <div className="round-chart__initial text-center">
            <p className="mb-0 strong h4 color--purple">Cutaneous <br/>mastocytosis</p>
          </div>
          <div className="round-chart__overlay text-center">
            <p className="strong fw mb-0">Cutaneous mastocytosis<sup>2-5</sup></p>
            <p className="fw mb-0 small">The skin is the only site of involvement in CM, whereas SM affects other organs besides skin.</p>
            <p className="fw mb-0 small">97% of patients (N=59) presenting with adult-onset CM actually have SM.*</p>
          </div>
        </RoundChart>
      </div>
      <div className="columns small-12 large-3 text-center mt-2">
        <RoundChart>
          <div className="round-chart__initial text-center">
            <p className="mb-0 strong h4 color--purple">Mast cell activation syndrome</p>
          </div>
          <div className="round-chart__overlay text-center">
            <p className="strong fw mb-0">Mast cell activation <br/>syndrome<sup>2,6</sup></p>
            <p className="fw mb-0 small">Bone marrow biopsies for patients with MCAS may show 1 or 2 minor criteria for SM but do not fulfill the complete <br/>WHO criteria.</p>
            <p className="fw mb-0 small">A full diagnostic workup, including high-sensitivity KIT D816V testing, could help in making a differential diagnosis.</p>
          </div>
        </RoundChart>
      </div>
      <div className="columns small-12 large-3 text-center mt-2">
        <RoundChart>
          <div className="round-chart__initial text-center">
            <p className="mb-0 strong h4 color--purple">Urticaria</p>
          </div>
          <div className="round-chart__overlay text-center">
            <p className="strong fw mb-0">Urticaria<sup>2</sup></p>
            <p className="fw mb-0 small">Urticaria pigmentosa is thought to be a self-resolving condition. Unlike forms of mastocytosis, there is rarely any internal organ involvement, as would be found in patients with SM.</p>
          </div>
        </RoundChart>
      </div>
      <div className="columns small-12 large-3 text-center mt-2">
        <RoundChart>
          <div className="round-chart__initial text-center">
            <p className="mb-0 strong h4 color--purple">IBD/IBS</p>
          </div>
          <div className="round-chart__overlay text-center">
            <p className="strong fw mb-0">IBD/IBS<sup>2,3,7</sup></p>
            <p className="fw mb-0 small">SM can cause GI symptoms similar to IBD, but mast cells may only mildly increase with IBD and aggregates of mast cells would not be observed.</p>
          </div>
        </RoundChart>
      </div>
    </div>

    <div className="row expanded align-center" style={{maxWidth: `1300px`}}>
      <div className="columns small-12 large-3 text-center mt-2">
        <RoundChart>
          <div className="round-chart__initial text-center">
            <p className="mb-0 strong h4 color--purple">Myeloproliferative</p>
          </div>
          <div className="round-chart__overlay text-center">
            <p className="strong fw mb-0">Myeloproliferative<sup>2,6,8,9</sup></p>
            <p className="fw mb-0 small">~70% of patients with advanced SM have an associated neoplasm (CMML, MPN, MDS). A specific diagnosis for SM requires a number of laboratory studies, including a high-sensitivity KIT D816V assay.</p>
          </div>
        </RoundChart>
      </div>
      <div className="columns small-12 large-3 text-center mt-2">
        <RoundChart>
          <div className="round-chart__initial text-center">
            <p className="mb-0 strong h4 color--purple">Malabsorption</p>
          </div>
          <div className="round-chart__overlay text-center">
            <p className="strong fw mb-0">Malabsorption<sup>3,7</sup></p>
            <p className="fw mb-0 small">GI symptoms are present in 60%–80% <br/>of SM patients.</p>
            <p className="fw mb-0 small">SM-related GI symptoms are largely caused by release of mediators and in rare, advanced forms by mast cell infiltration of the gut causing malabsorption.</p>
          </div>
        </RoundChart>
      </div>
      <div className="columns small-12 large-3 text-center mt-2">
        <RoundChart>
          <div className="round-chart__initial text-center">
            <p className="mb-0 strong h4 color--purple">Endocrine disorders</p>
          </div>
          <div className="round-chart__overlay text-center">
            <p className="strong fw mb-0">Endocrine disorders<sup>2,10</sup></p>
            <p className="fw mb-0 small">While endocrine disorders can cause flushing, recurrent, unexplained flushing may raise suspicion for SM.</p>
          </div>
        </RoundChart>
      </div>
    </div>

    <div className="row lg_mt-5 mt-5 align-center">
      <div className="columns text-center large-10">
        <div style={{borderRadius: `10px`, border: `1px solid #D6ABBB`, padding: `2rem 2rem 4rem 2rem`, position: `relative`}}>
          <a href="/DermatologyFlashcardUSDSA230004.pdf" target="_blank" className="no-ext h4 lg_mt-1 mt-1 text--regular color--purple">
            Your patients with cutaneous mastocytosis may have <br className="hide-for-medium"/>indolent SM<sup>5,11</sup>
            <span className="strong lg_mt-1 btn btn--pdf btn--tuck-border btn--small-90"><span>LEARN MORE&nbsp;</span><img alt="pdf download" src={pdfDownload} style={{transform: `translateY(-2px)`}} width="50" height="auto"/></span>
          </a>
        </div>
      </div>
    </div>

    <div className="row lg_mt-4 mt-4 align-center">
      <div className="columns text-center large-6">
        <div className="hr"></div>
          <p className="h4 lg_mt-1 mt-1 text--regular color--purple"><AnchorLink to="/is-it-sm/#case-studies" title="Case studies" className="strong color--purple underborder">Review case studies</AnchorLink> <br className="hide-for-medium"/>to see how high-sensitivity <br/>KIT D816V testing can help <br className="hide-for-medium"/>confirm SM<sup>6</sup></p>
        <div className="hr"></div>
      </div>
    </div>


    <div className="row lg_mt-4 mt-2 align-center medium-align-left">
      <div className="columns small-11 medium-expand">
        <ul className="hanging-ref color--purple">
          <li>
            <span className="ref">*</span>Based on a study with 59 patients with the clinical diagnosis of adult-onset mastocytosis in the skin established between 2004 and 2008.<sup>5</sup>
            <br/>
            CM=cutaneous mastocytosis; CMML=chronic myelomonocytic leukemia; GI=gastrointestinal; IBD=inflammatory bowel disease; IBS=irritable bowel syndrome;&nbsp;
            <br className="show-for-large"/>
            MCAS=mast cell activation syndrome; MDS=myelodysplastic syndrome; MPN=myeloproliferative neoplasm.
          </li>
        </ul>
      </div>
    </div>

    <div className="row lg_mt-5 mt-3 small-collapse medium-uncollapse">
      <div className="columns small-11 large-11">
        <div className="rose-card rose-card--left rose-card--left--indent text-left">
          <p className="h3 mb-0 lg_mt-2"><strong>High-sensitivity KIT D816V testing can be used as a first step to evaluate SM upon clinical suspicion<sup>6</sup></strong></p>
        </div>
      </div>
      <div className="columns small-8 medium-12 purple-ref">
        <p className="color--purple h5">Additional testing is required for diagnosis and subtyping.</p>
      </div>
    </div>

    <div className="row">
      <div className='columns'>
        <div className="row align-middle small-align-center">
          <div className="columns large-11">
            <div className="callout-rounded callout-rounded--grey callout-rounded--left">
              <div className="columns small-12 medium-expand">
                <div className="row align-middle">
                  <div className="columns">
                    <div className="bubble">
                      <p className="strong h4 color--purple text-center">Suspect SM?<sup>1</sup></p>
                      <ul className="ul-standard">
                        <li className="h5 no-list">Presence of:</li>
                        <li className="h5">Hallmark symptoms <br className="show-for-medium"/>(skin lesions, anaphylaxis, diarrhea)</li>
                        <li className="h5">Symptoms of mast cell activation</li>
                        <li className="h5">Elevated serum tryptase</li>
                      </ul>
                    </div>
                    <div className="bubble bubble--purple lg_mt-2 mt-2 text-center">
                      <p className="strong mb-0 h4 color--purple">Test for a leading indicator in diagnosis of SM<sup>6</sup></p>
                    </div>
                    <div className="lg_mt-2 mt-2">
                      <p className="strong h4 color--purple text-center mb-0">High-sensitivity KIT D816V testing <br className="show-for-large"/> (eg, ASO-qPCR or ddPCR)<sup>6</sup></p>
                    </div>
                  </div>
                  <div className="columns relative stretch small-12 medium-shrink mt-1 mb-1">
                    <div className="vr show-for-medium"></div>
                    <div className="hr hide-for-medium"></div>
                  </div>
                  <div className="columns">
                    <div className="row align-center">
                      <div className="columns large-11 text-center">
                        <p className="strong h4 color--purple">KIT D816V is the main driver of disease in ~95% of SM cases<sup>12-14</sup></p>
                      </div>
                    </div>
                    <div className="row align-center lg_mt-2 mt-2">
                      <div className="columns large-11 text-center">
                        <p className="strong h4 color--purple">Patients who test positive <br className="hide-for-medium"/>for KIT D816V using <br className="hide-for-medium"/>high-sensitivity KIT testing have an increased <br className="hide-for-medium"/>likelihood of SM<sup>6,12-14</sup></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row lg_mt-4 mt-4 align-center ref-box-bubbles">
                <div className="columns small-11 large-11">
                  <p className="h5 color--purple">Serum tryptase should always be measured in patients with <br className="hide-for-medium"/>suspected SM.<sup>15</sup></p>
                  <ul className="hanging-ref hanging-ref--no-ref lg_mt-1 mt-1">
                    <li><span className="ref">&nbsp;</span>ASO-qPCR=allele-specific oligonucleotide quantitative polymerase chain reaction; ddPCR=droplet digital PCR.</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="row lg_mt-7 mt-2 small-collapse medium-uncollapse">
      <div className="columns">
        <div className="hero-breaker hero-breaker--progress" style={{"--bg-image": `url(${assaysBg})`}}>
          <div className="row align-middle small-col-reverse">
            <div className="columns hero-breaker--person hero-breaker--person--left hero-breaker--person--assays small-12 large-5" style={{"--bg-image": `url(${assays})`, "--bg-image-mbl" : `url(${assaysMobile})`}}>
              <span className="visually-hidden">Woman with hand on chest</span>
            </div>
            <div className="columns small-12 large-7 text-right mt-4">
              <div className="row">
                <div className="columns large-10">
                  <p className="color--purple text--watch-quinn h2">Did you know?</p>
                  <p className="color--purple h2 lead">Low-sensitivity assays may fail to detect the KIT D816V mutation and potentially prolong  diagnosis<sup>6</sup></p>
                  <AnchorLink to="/test-for-kit-d816v/#find-a-lab" className="btn btn--rose btn--small-fw lg_mt-1 mt-1" title="Find a lab">
                    FIND A LAB
                  </AnchorLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="case-studies" className="row lg_mt-7 mt-3 align-center medium-align-left">
      <div className="columns small-11 large-10">
        <p className="h3 color--purple strong">Explore case studies of the types of patients <br className="show-for-medium"/>you may see in your practice</p>
        <p className="h5 text--regular color--purple mt-1">The profiles below are examples of different patient types, which may help you recognize patients in your practice who may be at risk of SM. Patient profiles are fictionalized through review of published literature and are not actual patients.</p>
      </div>
    </div>

    <div className="row lg_mt-4 mt-3 lg_mb-4 mb-3">
      <div className="columns text-center">
        <p className="lead strong color--purple mb-0">Select each of the cases below <br className="hide-for-medium"/> to learn more</p>
      </div>
    </div>

    <CasestudyModule/>

    <div className="row lg_mt-10 mt-7 lg_mb-7 mb-7 align-center medium-align-left">
      <div className="columns text-right small-11 medium-expand">
        <p className="h4 color--purple">Screen suspected patients <br className="hide-for-medium"/>with <br className="show-for-large"/>high-sensitivity KIT D816V assays</p>
        <Link className="btn btn--outline btn--icon uppercase mt-1" to="/test-for-kit-d816v/">TEST FOR KIT D816V <FontAwesomeIcon className="color--powder" icon={faAngleRight}/></Link>
      </div>
    </div>

    <FooterCrown type="suspect"/>

    <ReferenceBlock>
      <ol className="color--purple">
        <li>Shomali W, Gotlib J. <em>Hematology. </em>2018;2018(1):127-136.</li>
        <li>NORD. Rare disease database: mastocytosis. Accessed August 17, 2022. https://rarediseases.org/rare-diseases/mastocytosis/</li>
        <li>Zanelli M et al. <em>Cancers (Basel). </em>2021;13(13):3316.</li>
        <li>Jackson CW et al. <em>Int J Mol Sci. </em>2021;22(20):11270.</li>
        <li>Berezowska S et al. <em>Mod Pathol. </em>2014;27(1):19-29.</li>
        <li>Hoermann G et al. <em>J Allergy Clin Immunol Pract. </em>2022;10(8):1953-1963.</li>
        <li>Bedeir A et al. <em>Am J Surg Pathol. </em>2006;30(11):1478-1482.</li>
        <li>Reiter A et al. <em>Blood. </em>2020;135(16):1365-1376.</li>
        <li>Sperr WR et al. <em>Lancet Haematol. </em>2019;6(12):e638-e649.</li>
        <li>Carter MC et al. <em>Immunol Allergy Clin North Am. </em>2014;34(1):181-196.</li>
        <li>Fuchs D et al. <em>J Allergy Clin Immunol Pract. </em>2021;9(4):1705-1712.e4.</li>
        <li>Garcia-Montero AC et al. <em>Blood. </em>2006;108(7):2366-2372.</li>
        <li>Evans EK et al. <em>Sci Transl Med. </em>2017;9(414):eaao1690.</li>
        <li>Kristensen et al. <em>J Mol Diagn. </em>2011;13(2):180-188.</li>
        <li>Valent P et al. <em>J Allergy Clin Immunol Pract. </em>2022;10(8):1999-2012.e6.</li>
      </ol>
    </ReferenceBlock>
  </Layout>
)

export default SuspectSMPage
