import React, {useState, useEffect} from "react";

import expand from "../../images/expand.svg";
import collapse from "../../images/collapse.svg";

const RoundChart =({children})=>{
  const [isBig, setIsBig] = useState(false);
  const toggleChart =(e)=>{
    // let chartColl = document.getElementsByClassName('round-chart');
    // let charts = Array.from(chartColl);
    // console.log(charts);
    // for (var chart of charts) {
    //   if(chart.classList.contains('isBig')){
    //     chart.classList.remove('isBig');
    //   }
    // }
    setIsBig(!isBig);
    e.preventDefault();
  }
  return (
    <>
      <div className={isBig ? `round-chart isBig` : `round-chart`} onClick={(e)=>toggleChart(e)}>
        <button onClick={(e)=>toggleChart(e)}>
          {!isBig &&
            <>
            <span className="visually-hidden">Expand this graph</span><img src={expand} alt="Expand this graph"/>
            </>
          }
          {isBig &&
            <>
            <span className="visually-hidden">Collapse this graph</span><img src={collapse} alt="Expand this graph"/>
            </>
          }
        </button>
        {children}
      </div>
    </>
  );
}

export default RoundChart;
