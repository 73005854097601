import * as React from "react";

const CsPeter =()=>{
  return(
    <>
      <div className="row align-center peter-cs">

      <div className="columns small-12 large-12 lg_mb-2 mb-2 small-collapse medium-uncollapse">
        <div className="hr"></div>
      </div>

        <div className="columns small-11 large-11">
          <p className="strong color--powder h3">Peter:</p>
          <p className="strong color--purple h4">Indolent systemic mastocytosis presenting with frequent diarrhea and nausea</p>
          <div className="lg_mt-2 mt-2">
            <p className="strong h5 color--purple">Patient history and presentation:</p>
            <ul className="ul-standard color--purple text--regular">
              <li>Peter first presented to his gastroenterologist with diarrhea, abdominal pain, nausea, and weight loss</li>
              <li>Endoscopy showed inflammatory bowel disease managed with corticosteroids</li>
              <li>Peter returned to gastroenterologist reporting increased frequency of diarrhea and nausea that was preventing him from leaving the house</li>
              <li>Colonoscopy performed for further examination, revealing mucosal nodularity, triggering his gastroenterologist to take a sample for deeper analysis</li>
              <li>Histological sample revealed dense spindle-shaped mast cell infiltration of the lamina propria, showing involvement by mastocytosis</li>
              <li>Blood work that was taken aside colonoscopy showed elevated serum tryptase (79.5 ng/mL)</li>
              <li>Referred to hematologist on suspicion of underlying mast cell involvement</li>
            </ul>
          </div>



          <div className="lg_mt-2 mt-2">
            <p className="strong h5 color--purple">Full lab results:</p>
            <ul className="ul-standard color--purple text--regular">
              <li className="ul-standard">
                From gastroenterologist:
                <ul>
                  <li>Complete blood count and chemistry profile normal</li>
                  <li>Serum tryptase: elevated, 79.5 ng/mL</li>
                  <li>Referred to hematologist to conduct bone marrow biopsy</li>
                </ul>
              </li>
              <li className="ul-standard">
                From hematologist:
                <ul>
                  <li>IHC staining for CD25 and CD117 on bone marrow biopsy revealed CD25 spindle-shaped mast cells of &gt;20 cells per cluster, &gt;40% infiltration of cellularity by mast cells</li>
                  <li>KIT D816V in peripheral blood with high-sensitivity assay: positive mutation status</li>
                </ul>
              </li>
            </ul>
          </div>
        </div>

        <div className="columns small-12 large-11 lg_mt-3 mt-3">
          <div className="yellow-card yellow-card--even yellow-card--right text-left">
            <p className="h3 mb-0">With a positive KIT D816V mutation status, &gt;40% infiltration of cellularity by mast cells, aberrant expression of CD25, and irregular, spindle-shaped mast cells, <strong>the hematologist diagnosed Peter with indolent systemic mastocytosis</strong>.</p>
          </div>
        </div>

      </div>
    </>
  );
}

export default CsPeter;
